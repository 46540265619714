@tailwind base;
@tailwind components;
@tailwind utilities;

@layer {
    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
           -khtml-user-select: none; /* Konqueror HTML */
             -moz-user-select: none; /* Old versions of Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
      }

    .h-content {
        height: calc( 100vh - 64px);
    }

    .top-center {
        top: calc(50vh - 24px);
    }
}